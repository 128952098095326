import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

type ToastType = 'toast-info' | 'toast-error' | 'toast-success';

@Component({
  selector: 'lt-toast',
  templateUrl: 'toast.component.html',
  styleUrls: ['toast.component.scss'],
})
export class ToastComponent extends Toast {
  toastType: ToastType;

  constructor(protected toastService: ToastrService, public toastPackage: ToastPackage) {
    super(toastService, toastPackage);
    this.toastType = this.toastPackage.toastType as ToastType;
  }
}
