import { environment } from '../environments/environment';

export const IdentityApi = {
  GetIdentity: 'api/Identity/GetIdentity',
  UsernameTaken: 'api/Identity/UsernameTaken',
  EmailTaken: 'api/Identity/EmailTaken',
  ValidateToken: '/api/Identity/ValidateToken',
  SendEmailConfirmation: '/api/Identity/SendEmailConfirmation',
  ConfirmEmail: '/api/Identity/ConfirmEmail',
  SendPasswordReset: '/api/Identity/SendPasswordReset',
  ResetPassword: '/api/Identity/ResetPassword',
  ChangePassword: '/api/Identity/ChangePassword',
  CheckExternal: 'api/Identity/ExternalLoginTaken',
  SignInExternal: 'api/Identity/SignInExternal',
  SignUpExternal: 'api/Identity/SignUpExternal',
  SignIn: 'api/Identity/SignIn',
  SignUp: 'api/Identity/SignUp',
  SignOut: 'api/Identity/SignOut',
  Suicide: 'api/Identity/Suicide',
  GenerateAccessToken: 'api/Identity/GenerateAccessToken',
};

export const PaymentApi = {
  Payment: 'payments-api/Payment',
};

export const ProfileApi = {
  GetProfile: 'api/Profile/GetProfile',
  UpdateProfile: `api/Profile/UpdateProfile`,
  GetAvatar: 'api/Profile/GetAvatar',
  UploadAvatar: `api/Profile/UploadAvatar`,
  RenewMembership: 'api/Profile/RenewMembership',
  GetMemberships: 'api/Profile/GetMemberships',
  CancelMembership: 'api/Profile/CancelMembership',
  IsPaymentAcknowledged: '/api/Profile/IsPaymentAcknowledged',
  GetNotifications: '/api/Profile/GetNotifications',
  AcknowledgeNotificationById: '/api/Profile/AcknowledgeWithNotificationById',
};

export const LanguageApi = {
  GetLanguages: 'api/Language/GetLanguages',
  Flag: 'api/Language/GetFlag',
};

export const CourseApi = {
  GetAvailableCourses: 'api/Course/GetAvailableCourses',
  GetAvailableCoursesShort: 'api/Course/GetAvailableCoursesShort',
  GetCourseDetails: 'api/Course/GetCourseDetails',
  GetInProgressCourses: 'api/Course/GetInProgressCourses',
  GetCoursesItems: 'api/Course/GetCoursesItems',
  SetCourseUserVisibility: 'api/Course/SetCourseUserVisibility',
  ResetProgress: 'api/Course/ResetProgress',
  ResetProgressScoped: 'api/Course/ResetProgressScoped',
  GetCourseContent: 'api/Course/GetCourseContent',
  GetCourseContentScoped: '/api/Course/GetCourseContentScoped',
  UpdateUserCourseItems: 'api/Course/UpdateUserCourseItems',
  GetCourseUserProfile: 'api/Course/GetCourseUserProfile',
  UpdateCourseUserProfile: 'api/Course/UpdateCourseUserProfile',
  Search: 'api/Course/Search',
  CreateFavoriteItem: 'api/Course/CreateFavoriteItem',
  EditFavoriteItem: '/api/Course/EditFavoriteItem',
  DeleteFavoriteItem: '/api/Course/DeleteFavoriteItem',
};

export const LearnApi = {
  GetQuestion: '/api/Learn/GetQuestion',
  GetScopedQuestion: '/api/Learn/GetScopedQuestion',
  GetPreviousQuestion: '/api/Learn/GetPreviousQuestion',
  Answer: '/api/Learn/Answer',
  Postpone: '/api/Learn/Postpone',
  Complete: '/api/Learn/Complete',
  DownloadProgress: '/api/Learn/DownloadProgress',
};

export const FilterApi = {
  GetFilter: '/api/Filter/GetFilter',
  GetAvailableFilterOptions: '/api/Filter/GetAvailableFilterOptions',
  PersistFilter: '/api/Filter/PersistFilter',
};

export const ListenApi = {
  Play: '/api/Listen/Play',
  PlayScoped: '/api/Listen/PlayScoped',
  Complete: '/api/Listen/Complete',
};

export const VoiceActingApi = {
  DownloadItemsToVoice: '/api/VoiceActing/DownloadItemsToVoice',
  UploadVoiceActing: '/api/VoiceActing/UploadVoiceActing',
  GetVoiceActing: '/api/VoiceActing/GetVoiceActing',
  GetMergedVoiceActing: '/api/VoiceActing/GetMergedVoiceActing',
};

export const StatisticsApi = {
  GetStatistics: '/api/Statistics/GetStatistics',
  GetRankedStatistics: '/api/Statistics/GetPercentRankedStatistics',
  GetCourseStatistics: '/api/Statistics/GetCourseStatistics',
};

export const SignalR = {
  Recorder: environment.signalR + 'recorder',
};
