import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorTranslated extends MatPaginatorIntl {
  translateService: TranslateService;

  itemsPerPageLabel: string;
  firstPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;
  lastPageLabel: string;

  ofLabel: string;

  constructor() {
    super();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    const sectionEnd = page * pageSize + pageSize;

    return `${page * pageSize + 1} - ${sectionEnd > length ? length : sectionEnd} ${this.ofLabel} ${length}`;
  };

  injectTranslateService(translate: TranslateService): void {
    this.translateService = translate;

    this.translateService.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels(): void {
    this.itemsPerPageLabel = this.translateService.instant('COMMON.PAGINATOR.ITEMS_PER_PAGE');
    this.firstPageLabel = this.translateService.instant('COMMON.PAGINATOR.FIRST');
    this.nextPageLabel = this.translateService.instant('COMMON.PAGINATOR.NEXT');
    this.previousPageLabel = this.translateService.instant('COMMON.PAGINATOR.PREVIOUS');
    this.lastPageLabel = this.translateService.instant('COMMON.PAGINATOR.LAST');
    this.ofLabel = this.translateService.instant('COMMON.PAGINATOR.OF');
  }
}
