import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PasswordValidators } from '@shared/validators/custom/password/password.validators';

export class CommonValidators {
  static UsernameValidatorGroup = [Validators.pattern(/^.+@.+\..+$/)];

  static EmailValidatorGroup = [Validators.pattern(/^.+@.+\..+$/)];

  static PasswordValidatorGroup = [
    PasswordValidators.NonLatin(),
    Validators.minLength(6),
    PasswordValidators.OneLower(),
    PasswordValidators.OneUpper(),
    PasswordValidators.OneNumber(),
  ];

  static MustMatch(first: string, second: string): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (control.get(first) !== null && control.get(second) !== null) {
        const firstValue: string = control.get(first)?.value;
        const secondValue: string = control.get(second)?.value;

        if (firstValue !== secondValue) {
          control.get(second)?.setErrors({ dontMatch: true });
        } else {
          const errors = control.get(second)?.errors as {
            [key: string]: boolean;
          } | null;

          if (errors && errors.dontMatch) {
            delete errors.dontMatch;
          }

          const isOtherErrors = errors !== null && Object.keys(errors).length > 0;

          control.get(second)?.setErrors(isOtherErrors ? errors : null);
        }
      }

      return null;
    };
  }

  static MustNotMatch(first: string, second: string): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (control.get(first) !== null && control.get(second) !== null) {
        const firstValue: string = control.get(first)?.value;
        const secondValue: string = control.get(second)?.value;

        if (firstValue === secondValue) {
          control.get(second)?.setErrors({ match: true });
        } else {
          const errors = control.get(second)?.errors as {
            [key: string]: boolean;
          } | null;

          if (errors && errors.match) {
            delete errors.match;
          }

          const isOtherErrors = errors !== null && Object.keys(errors).length > 0;

          control.get(second)?.setErrors(isOtherErrors ? errors : null);
        }
      }

      return null;
    };
  }

  static minArrayLength(length: number): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (control.value.length >= length) return null;
      return { minLength: true };
    };
  }
}
