import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { TitleService } from '@modules/navigation/services/title.service';

@Injectable({ providedIn: 'root' })
export class CustomTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title, private titleService: TitleService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const rawTitle = this.buildTitle(routerState);
    if (rawTitle !== undefined) {
      // Ставим пока по умолчанию везде.
      this.title.setTitle(`Lingvotronic`);

      // дальше передаем в сервис который будем использовать уже по своему усмотрению. Возможно излишне, и стоит оставить все тут.
      this.titleService.updateTitle(rawTitle);
    }
  }
}
