import { SignInModel } from '@modules/identity/models/sign-in/sign-in.model';
import { GetValidationMessage } from '@shared/validators/validator.messages';
import { IdentityService } from '@modules/identity/services/identity.service';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CredentialsModel } from '@modules/identity/models/sign-up/sign-up.model';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { EmailConfirmationData } from '@modules/home/modules/user/models/types/types';
import { EmailConfirmationForm, TokenKind, TokenValidationModel } from '@modules/identity/models/token-validation/token-validation.model';
import { takeUntil } from 'rxjs/operators';
import { UpdatePasswordComponent } from '@modules/identity/components/update-password/update-password.component';
import { SignUpComponent } from '@modules/identity/components/sign-up/sign-up.component';

@Component({
  selector: 'lt-code-confirmation',
  templateUrl: './code-confirmation.component.html',
  styleUrls: ['../../styles/identity-modal.scss', './code-confirmation.component.scss'],
})
export class CodeConfirmationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('tokenInput', { static: true }) tokenInput: ElementRef;

  showErrorToken = false;

  destroy$ = new Subject<void>();

  emailConfirmationForm = this.fb.group<EmailConfirmationForm>({
    token: this.fb.control('', { validators: [Validators.required] }),
  });

  getValidationMessage = GetValidationMessage;
  TokenKind = TokenKind;

  constructor(
    @Inject(MAT_DIALOG_DATA) public userInfo: EmailConfirmationData,
    private readonly identityService: IdentityService,
    private dialogRef: MatDialogRef<CodeConfirmationComponent>,
    private dialog: MatDialog,
    private fb: NonNullableFormBuilder
  ) {}

  ngAfterViewInit() {
    this.emailConfirmationForm
      .get('token')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value.length === 3) {
          if (this.userInfo.confirmType === TokenKind.PasswordReset) {
            this.validatePasswordReset();
          } else {
            this.confirmEmail();
          }
        }
        this.showErrorToken = false;
      });
  }

  changeEmail(): void {
    this.dialogRef.close();
    this.dialog.open(SignUpComponent, {
      panelClass: 'dialog-with-title',
      autoFocus: false,
    });
  }

  confirmEmail(): void {
    this.showErrorToken = false;
    if (this.emailConfirmationForm.controls.token.value.length == 3) {
      this.emailConfirmationForm.controls.token.markAsTouched();
      const validateTokenPayload: TokenValidationModel = {
        kind: TokenKind.EmailConfirmation,
        login: this.userInfo.email,
        token: this.emailConfirmationForm.controls.token.value,
      };
      this.identityService.validateToken(validateTokenPayload).subscribe((isValid) => {
        if (!isValid) {
          this.showErrorToken = true;
          this.emailConfirmationForm.get('token')?.setErrors({ wrong: true });
          return;
        }

        const confirmCredentials: CredentialsModel = {
          login: validateTokenPayload.login,
          token: validateTokenPayload.token,
        };
        this.identityService.confirmEmail(confirmCredentials).subscribe(() => {
          this.processSignIn();
        });
      });
    }
  }

  processSignIn(): void {
    this.dialogRef.close();
    const signInPayload: SignInModel = {
      login: this.userInfo.email,
      password: this.userInfo.password,
    };
    this.identityService.signIn(signInPayload).subscribe();
    this.identityService.saveEmail('');
  }

  validatePasswordReset(): void {
    this.emailConfirmationForm.controls.token.markAsTouched();
    const validateTokenPayload: TokenValidationModel = {
      kind: TokenKind.PasswordReset,
      login: this.userInfo.email,
      token: this.emailConfirmationForm.controls.token.value,
    };
    this.identityService.validateToken(validateTokenPayload).subscribe((isValid) => {
      if (!isValid) {
        this.showErrorToken = true;
        this.emailConfirmationForm.get('token')?.setErrors({ wrong: true });
        return;
      }

      this.dialogRef.close();
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialog.open(UpdatePasswordComponent, {
          panelClass: 'dialog-with-title',
          data: {
            email: validateTokenPayload.login,
            token: validateTokenPayload.token,
          },
          autoFocus: false,
        });
      });
    });
  }

  onBlur(): void {
    this.tokenInput.nativeElement.focus();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
