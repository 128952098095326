import { Injectable } from '@angular/core';
import { isPresent } from './slim-loading-bar.utils';
import { Subject, Observable } from 'rxjs';

export enum SlimLoadingBarEventType {
  PROGRESS,
  VISIBLE,
}

export class SlimLoadingBarEvent {
  constructor(public type: SlimLoadingBarEventType, public value: any) {}
}

@Injectable()
export class SlimLoadingBarService {
  private _progress = 0;
  private _visible = true;
  private _intervalCounterId = 0;
  public interval = 500;

  private eventSource: Subject<SlimLoadingBarEvent> = new Subject<SlimLoadingBarEvent>();
  public events: Observable<SlimLoadingBarEvent> = this.eventSource.asObservable();

  set progress(value: number) {
    if (isPresent(value)) {
      if (value > 0) {
        this.visible = true;
      }
      this._progress = value;
      this.emitEvent(new SlimLoadingBarEvent(SlimLoadingBarEventType.PROGRESS, this._progress));
    }
  }

  get progress(): number {
    return this._progress;
  }

  set visible(value: boolean) {
    if (isPresent(value)) {
      this._visible = value;
      this.emitEvent(new SlimLoadingBarEvent(SlimLoadingBarEventType.VISIBLE, this._visible));
    }
  }

  get visible(): boolean {
    return this._visible;
  }

  private emitEvent(event: SlimLoadingBarEvent) {
    if (this.eventSource) {
      this.eventSource.next(event);
    }
  }

  start() {
    this.stop();
    this.visible = true;
    this._intervalCounterId = window.setInterval(() => {
      this.progress++;
      if (this.progress === 100) {
        this.complete();
      }
    }, this.interval);
  }

  stop() {
    if (this._intervalCounterId) {
      clearInterval(this._intervalCounterId);
      this._intervalCounterId = 0;
    }
  }

  complete() {
    this.progress = 100;
    this.stop();
    setTimeout(() => {
      this.visible = false;
      setTimeout(() => {
        this.progress = 0;
      }, 250);
    }, 250);
  }
}
