import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  $theme: Observable<string>;
  private themeSubject: BehaviorSubject<string>;

  constructor() {
    this.themeSubject = new BehaviorSubject<string>('default-theme');
    this.$theme = this.themeSubject.asObservable();

    const loadedTheme = ThemeService.loadLocalStorageTheme();

    if (loadedTheme !== undefined) {
      this.theme = loadedTheme;
    }
  }

  get theme(): string {
    return this.themeSubject.getValue();
  }

  set theme(newTheme: string) {
    this.themeSubject.next(newTheme);

    ThemeService.setLocalStorageTheme(newTheme);
  }

  private static loadLocalStorageTheme(): string | undefined {
    const localStorageIdentityItem = localStorage.getItem('theme');

    if (localStorageIdentityItem !== null) {
      return JSON.parse(localStorageIdentityItem);
    } else {
      return undefined;
    }
  }

  private static setLocalStorageTheme(newTheme: string | undefined): void {
    if (newTheme) {
      localStorage.setItem('theme', JSON.stringify(newTheme));
    } else {
      localStorage.removeItem('theme');
    }
  }
}
