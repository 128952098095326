export const ValidatorMessages = new Map([
  [
    'login',
    new Map([
      ['notFound', 'COMMON.VALIDATION.LOGIN.NOT_FOUND'],
      ['pattern', 'COMMON.VALIDATION.EMAIL.PATTERN'],
      ['required', 'COMMON.VALIDATION.EMAIL.REQUIRED'],
    ]),
  ],
  [
    'username',
    new Map([
      ['taken', 'COMMON.VALIDATION.USERNAME.NOT_FOUND'],
      ['required', 'COMMON.VALIDATION.USERNAME.REQUIRED'],
      ['pattern', 'COMMON.VALIDATION.EMAIL.PATTERN'],
    ]),
  ],
  [
    'email',
    new Map([
      ['required', 'COMMON.VALIDATION.EMAIL.REQUIRED'],
      ['pattern', 'COMMON.VALIDATION.EMAIL.PATTERN'],
      ['taken', 'COMMON.VALIDATION.EMAIL.TAKEN'],
    ]),
  ],
  [
    'password',
    new Map([
      ['dontMatch', 'COMMON.VALIDATION.PASSWORD.DONT_MATCH'],
      ['nonLatin', 'COMMON.VALIDATION.PASSWORD.NON_LATIN'],
      ['match', 'COMMON.VALIDATION.PASSWORD.MATCH'],
      ['wrong', `COMMON.VALIDATION.PASSWORD.WRONG`],
      ['required', 'COMMON.VALIDATION.PASSWORD.REQUIRED'],
      ['minlength', 'COMMON.VALIDATION.PASSWORD.MIN_LENGTH'],
      ['oneLower', 'COMMON.VALIDATION.PASSWORD.ONE_LOWER'],
      ['oneUpper', 'COMMON.VALIDATION.PASSWORD.ONE_UPPER'],
      ['oneNumber', 'COMMON.VALIDATION.PASSWORD.ONE_NUMBER'],
      ['oneSpecial', 'COMMON.VALIDATION.PASSWORD.ONE_SPECIAL'],
    ]),
  ],
  [
    'suicide',
    new Map([
      ['required', 'COMMON.VALIDATION.PASSWORD.REQUIRED'],
      ['wrong', `COMMON.VALIDATION.PASSWORD.WRONG`],
    ]),
  ],
  [
    'courseName',
    new Map([
      ['required', 'COMMON.VALIDATION.UPLOAD_COURSE.REQUIRED'],
      ['taken', 'COMMON.VALIDATION.COURSE_NAME.TAKEN'],
    ]),
  ],
  ['authorName', new Map([['required', 'COMMON.VALIDATION.UPLOAD_COURSE.REQUIRED']])],
  ['description', new Map([['required', 'COMMON.VALIDATION.UPLOAD_COURSE.REQUIRED']])],
  [
    'nativeLanguage',
    new Map([
      ['required', 'COMMON.VALIDATION.UPLOAD_COURSE.REQUIRED'],
      ['match', 'COMMON.VALIDATION.NATIVE_LANGUAGE.MATCH'],
    ]),
  ],
  [
    'learningLanguage',
    new Map([
      ['required', 'COMMON.VALIDATION.UPLOAD_COURSE.REQUIRED'],
      ['match', 'COMMON.VALIDATION.LEARNING_LANGUAGE.MATCH'],
    ]),
  ],
  [
    'file',
    new Map([
      ['required', 'COMMON.VALIDATION.FILE.REQUIRED'],
      ['wrongExtension', 'COMMON.VALIDATION.FILE.WRONG_EXTENSION'],
    ]),
  ],
  [
    'token',
    new Map([
      ['timeout', 'COMMON.VALIDATION.CONFIRMATION.TIMEOUT'],
      ['wrong', 'COMMON.VALIDATION.CONFIRMATION.WRONG'],
    ]),
  ],
]);

export function GetValidationMessage(validatorType: string, currentErrors: Record<string, unknown>): string {
  if (currentErrors === null) return '';

  const messages = ValidatorMessages.get(validatorType) as Map<string, string>;

  const errors = Object.keys(currentErrors).map((error) => {
    const potentialMessage = messages.get(error);

    if (potentialMessage !== undefined) {
      return potentialMessage;
    }
  });

  return errors[0] as string;
}
