import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogWithTitleComponent } from '@shared/common/UI/dialog-with-title/dialog-with-title.component';

@Component({
  selector: 'lt-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  standalone: true,
  imports: [DialogWithTitleComponent, MatDialogModule],
})
export class PrivacyPolicyComponent {
  title = 'HOME.PROFILE.PRIVACY_POLICY';
}
