<lt-dialog-with-title [title]="'IDENTITY.RESET_PASSWORD.TITLE'">
  <form class="identity-form" (ngSubmit)="onSubmit()" [formGroup]="updatePasswordForm">
    <p class="identity-form__heading">{{ 'IDENTITY.UPDATE_PASSWORD.HEADING' | translate }}</p>

    <div class="identity-form__fields">
      <mat-form-field
        [color]="controlColorPassword"
        floatLabel="auto"
        appearance="outline"
        [class.mat-form-field-invalid]="showErrorPassword"
        class="lt-textfield"
      >
        <mat-label>{{ 'COMMON.INPUT.NEW_PASSWORD' | translate }} </mat-label>

        <input
          #passwordInput
          (blur)="onBlur()"
          formControlName="password"
          [type]="showConfirmPassword ? 'text' : 'password'"
          matInput
          type="password"
          (keydown)="backToTyping($event)"
        />
        <button
          type="button"
          matSuffix
          lt-button
          disabledHover
          disabledRipple
          aria-label="Toggle visibility"
          class="visibility-button"
          (mousedown)="showPassword($event)"
        >
          <mat-icon svgIcon="sign-in-visibility" class="visibility-button--dark"></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <lt-check-pass-list class="identity-form__check-pass" [isTyping]="typingMode" [validation]="validation"></lt-check-pass-list>

    <button class="identity-form__btn" lt-button bgColor="accent" (click)="checkInputsFocus()">
      {{ 'HOME.PROFILE.CHANGE_PASSWORD.HINT' | translate }}
    </button>
  </form>
</lt-dialog-with-title>
