import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatisticsApi } from '@assets/api-urls';
import { CourseEndedStatistics, RankedStatistics, Statistics, StatisticsRequestParams } from '@modules/statistics/model/statistics.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StatisticsClient {
  constructor(private http: HttpClient) {}

  getCourseEndedStatistics(courseId: string): Observable<CourseEndedStatistics> {
    const params = this.createRequestParams({ courseId });
    return this.http.get<CourseEndedStatistics>(StatisticsApi.GetCourseStatistics, { params });
  }

  getRankedStatistics(reqParams?: StatisticsRequestParams): Observable<RankedStatistics | null> {
    const params = this.createRequestParams(reqParams);

    const request$ = this.http.get<RankedStatistics | HttpErrorResponse>(StatisticsApi.GetRankedStatistics, { params });
    return request$.pipe(
      catchError(() => {
        return of(null);
      }),
      map((result: RankedStatistics | null) => {
        return result;
      })
    );
  }

  getStatistics(reqParams?: StatisticsRequestParams): Observable<Statistics> {
    let httpParams = new HttpParams();
    httpParams = this.createRequestParams(reqParams);

    return this.http.get<Statistics>(StatisticsApi.GetStatistics, { params: httpParams });
  }

  private createRequestParams(reqParams?: StatisticsRequestParams) {
    let params = new HttpParams();

    if (reqParams?.languageFilter?.learningLanguage) {
      params = params.append('LearningLanguageCode', reqParams.languageFilter.learningLanguage.code);
    }

    if (reqParams?.languageFilter?.nativeLanguage) {
      params = params.append('NativeLanguageCode', reqParams.languageFilter.nativeLanguage.code);
    }

    if (reqParams?.myCourses) {
      params = params.append('MyCourses', 'True');
    }

    if (reqParams?.courseId) {
      params = params.append('CourseId', reqParams.courseId);
    }

    if (reqParams?.favorite) {
      params = params.append('Favorite', reqParams.favorite);
    }

    if (reqParams?.wordsFilter?.includeCategories) {
      reqParams.wordsFilter.includeCategories.forEach((category) => {
        params = params.append('Filter.IncludeCategories', category);
      });
    }

    if (reqParams?.wordsFilter?.includeTenses) {
      reqParams.wordsFilter.includeTenses.forEach((tense) => {
        params = params.append('Filter.IncludeTenses', tense);
      });
    }

    return params;
  }
}
