import { Injectable } from '@angular/core';
import { Identity } from '@shared/models/identity.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IdentityStorage {
  private identitySubject$ = new BehaviorSubject<Identity | undefined>(undefined);
  public identity$ = this.identitySubject$ as Observable<Identity | undefined>;

  private loggedInSubject$ = new BehaviorSubject<boolean>(false);
  public loggedIn$ = this.loggedInSubject$ as Observable<boolean>;

  constructor() {
    this.loggedIn = this.checkLoggedInFromLocalStorage();
  }

  set loggedIn(isLogged: boolean) {
    this.setLoggedInToLocalStorage(isLogged);

    this.loggedInSubject$.next(isLogged);
  }

  get loggedIn(): boolean {
    return this.loggedInSubject$.getValue();
  }

  get identity(): Identity | undefined {
    return this.identitySubject$.getValue();
  }

  set identity(identity: Identity | undefined) {
    this.identitySubject$.next(identity);
  }

  checkLoggedInFromLocalStorage(): boolean {
    const loggedIn = localStorage.getItem('loggedIn');

    if (loggedIn) {
      return JSON.parse(loggedIn);
    } else {
      return false;
    }
  }

  private setLoggedInToLocalStorage(isLogged: boolean): void {
    if (isLogged) {
      localStorage.setItem('loggedIn', JSON.stringify(isLogged));
    } else {
      localStorage.removeItem('loggedIn');
    }
  }
}
