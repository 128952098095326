import { LTTableColumnDirective } from 'src/app/UI/components/lt-table/lt-table-column.directive';
import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagingResult } from '@shared/models/paging.model';
import { SortModel } from '@shared/models/sort.model';

@Component({
  selector: 'lt-table',
  templateUrl: './lt-table.component.html',
  styleUrls: ['./lt-table.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class LTTableComponent<T> implements AfterContentInit {
  @Input() dataSource: T[] = []; // Данные для отображения
  @Input() paging: PagingResult;
  @Output() sortChange = new EventEmitter<SortModel>(); // Для передачи события сортировки
  @ContentChildren(LTTableColumnDirective) columns: QueryList<LTTableColumnDirective<T>>; // Колонки и их параметры

  currentSort: SortModel;

  ngAfterContentInit(): void {
    const firstSortableColumn = this.columns.find((column) => column.sortable);
    if (firstSortableColumn) {
      this.currentSort = { key: firstSortableColumn.field as string, ascending: true };
    }
  }

  onSort(column: LTTableColumnDirective<T>): void {
    const ascending = this.currentSort.key === column.field ? !this.currentSort.ascending : true;
    this.currentSort = { key: column.field as string, ascending };

    this.sortChange.emit(this.currentSort);
  }
}
