import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IdentityStorage } from '@shared/services/identity.storage';
import { ThemeService } from '@shared/services/theme.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'lt-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  currentTheme: string;

  destroy$ = new Subject<void>();

  loggedIn$: Observable<boolean>;

  constructor(
    private cdr: ChangeDetectorRef,
    private overlayContainer: OverlayContainer,
    private themeService: ThemeService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private identityStorage: IdentityStorage
  ) {
    this.currentTheme = this.themeService.theme;

    themeService.$theme.subscribe((newTheme) => {
      this.onThemeChange(newTheme);
    });

    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icons-sprite.svg'));

    this.loggedIn$ = this.identityStorage.loggedIn$;
  }

  ngOnInit(): void {
    this.overlayContainer.getContainerElement().classList.add(this.currentTheme);
  }

  onThemeChange(theme: string): void {
    document.body.classList.remove(this.currentTheme);

    this.currentTheme = theme;
    document.body.classList.add(this.currentTheme);

    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(theme);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
