import { animate, state, style, transition, trigger } from '@angular/animations';

export const sliderAnimation = [
  trigger('slideInOut', [
    state(
      'leftOut',
      style({
        opacity: 0,
        transform: 'translateX(-100%)',
      })
    ),
    state(
      'rightOut',
      style({
        opacity: 0,
        transform: 'translateX(100%)',
      })
    ),
    state(
      'visible',
      style({
        opacity: 1,
        transform: 'translateX(0)',
      })
    ),
    state(
      'invisible',
      style({
        opacity: 0,
        transform: 'translateX(100%)',
      })
    ),
    transition('* <=> *', [animate('300ms ease-out')]),
  ]),
];
