import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailConfirmationData } from '@modules/home/modules/user/models/types/types';
import { CheckPasswordListComponent } from '@modules/identity/components/check-password/check-password-list.component';
import { ResetPasswordModel } from '@modules/identity/models/reset-password/reset-password.model';
import { SignInModel } from '@modules/identity/models/sign-in/sign-in.model';
import { IdentityService } from '@modules/identity/services/identity.service';
import { DialogWithTitleComponent } from '@shared/common/UI/dialog-with-title/dialog-with-title.component';
import { SharedModule } from '@shared/modules/shared.module';
import { CommonValidators } from '@shared/validators/common.validators';
import { Subject, takeUntil } from 'rxjs';

export interface UpdatePasswordForm {
  password: FormControl<string>;
}

@Component({
  standalone: true,
  imports: [SharedModule, CheckPasswordListComponent, DialogWithTitleComponent],
  selector: 'lt-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['../../styles/identity-modal.scss', './update-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatePasswordComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('passwordInput') passwordInput: ElementRef;

  updatePasswordForm: FormGroup<UpdatePasswordForm>;
  showErrorPassword = false;
  showConfirmPassword = false;
  typingMode = true;

  destroy$ = new Subject<void>();

  validation = {
    hasMinlengthError: true,
    hasNonLatinError: true,
    hasNumberError: true,
    hasRegisterError: true,
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: NonNullableFormBuilder,
    private identityService: IdentityService,
    private dialogRef: MatDialogRef<UpdatePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public userInfo: EmailConfirmationData
  ) {
    this.updatePasswordForm = this.fb.group<UpdatePasswordForm>({
      password: this.fb.control('', {
        validators: [Validators.required, ...CommonValidators.PasswordValidatorGroup],
      }),
    });
  }

  ngAfterViewChecked(): void {
    this.passwordInput.nativeElement.focus();
    this.cdr.detectChanges();
  }

  onSubmit() {
    if (this.updatePasswordForm.controls.password.valid && this.userInfo.token !== undefined) {
      const credentials: ResetPasswordModel = {
        login: this.userInfo.email,
        token: this.userInfo.token,
        password: this.updatePasswordForm.controls.password.value,
      };
      this.identityService.resetPassword(credentials).subscribe(() => {
        this.dialogRef.close();
        const credentials: SignInModel = {
          login: this.userInfo.email,
          password: this.updatePasswordForm.controls.password.value,
        };
        this.identityService.signIn(credentials, true).subscribe();
      });
    }
  }

  // ! в процессе
  get controlColorPassword() {
    return this.updatePasswordForm.controls.password.valid ? 'accent' : 'primary';
  }

  onBlur(): void {
    this.showErrorPassword = true;
    this.typingMode = false;
    this.checkPasswordErrors();
    this.checkInputsFocus();
  }

  showPassword(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  checkInputsFocus(): void {
    this.showErrorPassword = true;
    this.typingMode = false;
    this.passwordInput.nativeElement.focus();
  }

  backToTyping(event: KeyboardEvent): void {
    if (event.code !== 'Enter') {
      this.updatePasswordForm.controls.password.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.checkPasswordErrors();
      });
      this.showErrorPassword = false;
      this.typingMode = true;
      this.updatePasswordForm.controls.password.markAsUntouched();
    }
  }

  checkPasswordErrors(): void {
    const hasMinlengthError =
      this.updatePasswordForm.controls.password.hasError('minlength') || this.updatePasswordForm.controls.password.hasError('required');
    const hasNonLatinError = this.updatePasswordForm.controls.password.hasError('nonLatin');
    const hasRegisterError =
      this.updatePasswordForm.controls.password.hasError('oneUpper') || this.updatePasswordForm.controls.password.hasError('oneLower');
    const hasNumberError = this.updatePasswordForm.controls.password.hasError('oneNumber');

    this.validation = {
      hasMinlengthError,
      hasNonLatinError,
      hasNumberError,
      hasRegisterError,
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
