export enum Role {
  Admin = 'Admin',
  User = 'User',
  None = 'None',
}

export const compareRole = (role1: Role, role2: Role) => {
  let role1Priority = 0;
  for (const role in Role) {
    if (role === role1) {
      break;
    } else {
      role1Priority++;
    }
  }

  let role2Priority = 0;
  for (const role in Role) {
    if (role === role2) {
      break;
    } else {
      role2Priority++;
    }
  }

  return role2Priority - role1Priority >= 0;
};
