import { Injectable } from '@angular/core';
import { IdentityStorage } from '@shared/services/identity.storage';
import { IdentityApi } from '@assets/api-urls';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { SignInModel } from '@modules/identity/models/sign-in/sign-in.model';
import { SignUpModel } from '@modules/identity/models/sign-up/sign-up.model';
import { SignInResult } from '@modules/identity/models/sign-in/sign-in.result';
import { InterfaceLanguageService } from '@shared/services/interface-language.service';
import { LoadingBarService } from '@modules/navigation/components/loading-bar/loading-bar.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SendResetPasswordResult } from '@modules/identity/models/reset-password/send-reset-password.result';
import { ResetPasswordModel } from '@modules/identity/models/reset-password/reset-password.model';
import { ResetPasswordResult } from '@modules/identity/models/reset-password/reset-password.result';
import { ChangePasswordModel } from '@modules/home/modules/profile/models/change-password/change-password.model';
import { ChangePasswordResult } from '@modules/home/modules/profile/models/change-password/change-password.result';
import { Identity } from '@shared/models/identity.model';
import { TokenValidationModel } from '@modules/identity/models/token-validation/token-validation.model';
import { TokenValidationResult } from '../models/token-validation/token-validation.result';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '@shared/enums/role.enum';

@Injectable({ providedIn: 'root' })
export class IdentityService {
  constructor(
    private httpClient: HttpClient,
    private interfaceLanguageService: InterfaceLanguageService,
    private identityStorage: IdentityStorage,
    private loadingBarService: LoadingBarService,
    private router: Router,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  getIdentity(): Observable<Identity | null> {
    this.loadingBarService.start();

    const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = new HttpParams().set('TimeZone', timezoneId);

    return this.httpClient.get<Identity>(IdentityApi.GetIdentity, { params }).pipe(
      map((response) => {
        this.identityStorage.identity = response;
        this.interfaceLanguageService.interfaceLanguage = response.interfaceLanguageCode;
        this.identityStorage.loggedIn = true;
        return response;
      }),
      catchError(() => {
        this.identityStorage.loggedIn = false;
        return of(null);
      }),
      finalize(() => {
        this.loadingBarService.stop();
      })
    );
  }

  checkLoggedIn() {
    return this.getIdentity().pipe(
      map((identity) => {
        if (identity) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  usernameTaken(username: string): Observable<{ taken: boolean }> {
    return this.httpClient.get<{ taken: boolean }>(IdentityApi.UsernameTaken, {
      params: new HttpParams().set('Username', username),
    });
  }

  emailTaken(email: string): Observable<{ taken: boolean }> {
    return this.httpClient.get<{ taken: boolean }>(IdentityApi.EmailTaken, {
      params: new HttpParams().set('Email', email),
    });
  }

  showNotificationAfterReset() {
    this.toastrService.success(this.translateService.instant('HOME.PROFILE.NOTIFICATION.SUCCESS.PASSWORD'));
  }

  signIn(credentials: SignInModel, afterReset?: boolean): Observable<void | HttpErrorResponse> {
    return this.httpClient.post(IdentityApi.SignIn, credentials).pipe(
      map((response: SignInResult) => {
        const url = response.role === Role.Admin ? '/admin' : '/home';

        this.router.navigateByUrl(url).then(() => {
          if (afterReset) {
            this.showNotificationAfterReset();
          }
        });
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  }

  signUp(credentials: SignUpModel): void {
    this.loadingBarService.start();

    this.httpClient
      .post(IdentityApi.SignUp, credentials)
      .pipe(
        finalize(() => {
          this.loadingBarService.stop();
        })
      )
      .subscribe(() => {
        this.sendEmailConfirmation(credentials.email);
      });
  }

  sendEmailConfirmation(login: string): void {
    this.loadingBarService.start();
    this.httpClient
      .post<void>(IdentityApi.SendEmailConfirmation, { login })
      .pipe(
        finalize(() => {
          this.loadingBarService.complete();
        })
      )
      .subscribe();
  }

  confirmEmail(credentials: { login: string; token: string }): Observable<void> {
    return this.httpClient.post<void>(IdentityApi.ConfirmEmail, credentials);
  }

  sendPasswordReset(login: string): Observable<SendResetPasswordResult> {
    this.loadingBarService.start();
    return this.httpClient.post<SendResetPasswordResult>(IdentityApi.SendPasswordReset, { login }).pipe(
      finalize(() => {
        this.loadingBarService.complete();
      })
    );
  }

  resetPassword(credentials: ResetPasswordModel): Observable<ResetPasswordResult> {
    this.loadingBarService.start();
    return this.httpClient.post<ResetPasswordResult>(IdentityApi.ResetPassword, credentials).pipe(
      finalize(() => {
        this.loadingBarService.complete();
      })
    );
  }

  changePassword(changePasswordModel: ChangePasswordModel): Observable<ChangePasswordResult> {
    return this.httpClient.post<ChangePasswordResult>(IdentityApi.ChangePassword, changePasswordModel);
  }

  validateToken(tokenValidationOptions: TokenValidationModel): Observable<boolean> {
    return this.httpClient.post<TokenValidationResult>(IdentityApi.ValidateToken, tokenValidationOptions).pipe(
      map((response) => {
        return response.valid;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  suicide(body: { password: string }): Observable<void> {
    return this.httpClient.post<void>(IdentityApi.Suicide, body);
  }

  signOut(): void {
    this.loadingBarService.start();
    sessionStorage.clear();

    this.httpClient
      .get(IdentityApi.SignOut, {})
      .pipe(
        catchError((err: unknown) => {
          return of(err);
        }),
        finalize(() => {
          this.loadingBarService.stop();
        })
      )
      .subscribe(() => {
        this.identityStorage.identity = undefined;
        this.identityStorage.loggedIn = false;
        this.router.navigateByUrl('/');
      });
  }

  public email$ = new BehaviorSubject<string>('');
  public saveEmail(email: string) {
    this.email$.next(email);
  }
}
