import { Injectable } from '@angular/core';
import { StatisticsClient } from '@modules/statistics/client/statistics.client';
import { CourseEndedStatistics, RankedStatistics, Statistics, StatisticsRequestParams } from '@modules/statistics/model/statistics.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor(private client: StatisticsClient) {}

  private statisticsSubject$ = new BehaviorSubject<Statistics>({
    wordsToRepeatToday: { current: 0, max: 0 },
    newWordsForToday: { current: 0, max: 0 },
    wordsInProgress: { current: 0, max: 0 },
    wordsLearned: { current: 0, max: 0 },
    completed: false,
  });

  public statistics$ = this.statisticsSubject$.asObservable();

  private rankedStatisticsSubject$ = new BehaviorSubject<RankedStatistics | null>({
    wordsLearnedRank: { value: 0, percentRank: 0 },
    listenDuration: { value: 0, percentRank: 0 },
    uniqueWordsListened: { value: 0, percentRank: 0 },
    wordsListened: { value: 0, percentRank: 0 },
    writings: { value: 0, percentRank: 0 },
    cards: { value: 0, percentRank: 0 },
    correctAnswers: { value: 0, percentRank: 0 },
  });

  public rankedStatistics$ = this.rankedStatisticsSubject$.asObservable();

  private courseStatisticsSubject$ = new BehaviorSubject<Statistics | undefined>(undefined);
  public courseStatistics$ = this.courseStatisticsSubject$.asObservable();

  private courseCompleteSubject$ = new Subject<boolean>();
  public courseComplete$ = this.courseCompleteSubject$.asObservable();

  private scopeStatisticsTitleSubject$ = new BehaviorSubject<string>('');
  public scopeStatisticsTitle$ = this.scopeStatisticsTitleSubject$.asObservable();

  private scopeRequestSubject$ = new BehaviorSubject<StatisticsRequestParams | undefined>({});
  public scopeRequest$ = this.scopeRequestSubject$.asObservable();

  favoriteItemsCountSubject$ = new BehaviorSubject<number>(0);
  favoriteItemsCount$ = this.favoriteItemsCountSubject$ as Observable<number>;

  public getStatistics(params?: StatisticsRequestParams, shouldUpdateBar = true): Observable<Statistics> {
    return this.client.getStatistics(params).pipe(
      tap((statistics) => {
        if (params?.courseId) {
          this.courseStatisticsSubject$.next(statistics);
          if (statistics.completed) {
            this.courseCompleteSubject$.next(statistics.completed);
          }
        }
        if (shouldUpdateBar) {
          this.statisticsSubject$.next(statistics);
          this.scopeRequestSubject$.next(params);
        }
        if (params?.favorite) {
          this.favoriteItemsCountSubject$.next(statistics.wordsLearned.max);
        }
      })
    );
  }

  public getRankedStatistics(params?: StatisticsRequestParams): Observable<RankedStatistics | null> {
    return this.client.getRankedStatistics(params).pipe(
      tap((stats) => {
        this.rankedStatisticsSubject$.next(stats);
      })
    );
  }

  public getCourseEndedStatistics(courseId: string): Observable<CourseEndedStatistics> {
    return this.client.getCourseEndedStatistics(courseId);
  }

  public updateStatisticsTitle(scopeTitle: string): void {
    this.scopeStatisticsTitleSubject$.next(scopeTitle);
  }
}
