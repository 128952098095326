import { FormControl } from '@angular/forms';

export enum TokenKind {
  EmailConfirmation = 'EmailConfirmation',
  PasswordReset = 'PasswordReset',
}

export type TokenValidationModel = {
  kind: TokenKind;
  login: string;
  token: string;
};

export interface EmailConfirmationForm {
  token: FormControl<string>;
}
