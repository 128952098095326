<li class="validation-item">
  <mat-icon
    [ngClass]="{
      'validation-item__icon--valid': !hasError,
      'validation-item__icon--invalid': hasError && !isTyping
    }"
    class="validation-item__icon"
    svgIcon="sign-up-check"
  ></mat-icon>
  <p
    [ngClass]="{
      'validation-item__text--valid': !hasError,
      'validation-item__text--invalid': hasError && !isTyping
    }"
    class="validation-item__text"
  >
    <ng-content></ng-content>
  </p>
</li>
