import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { MatRippleModule, RippleAnimationConfig } from '@angular/material/core';

/*
Напоминает material но немного отличается.
Если цвет кнопки, один из "основных" (primary, accent, warn), то достаточно указать только это. (Речь именно про цвет)
<button lt-button bgColor="accent">

Если цвет кнопки отличается, но при этом он не БЕЛЫЙ и не ПРОЗРАЧНЫЙ(такой цвет фона у большинства иконок)
в таком случае указываем other, и локально в нужном компоненте, нужно указать в стилях переменную --btn-background-color с нужным цветом.

<button lt-button bgColor="other">

.custom-btn {
  --btn-background-color: var(--color-name);
}

Если же кнопка белая, или прозрачная, тогда мы не указываем bgColor, то цвет кнопки задаем как обычно.
(для прозрачной кнопки можно ничего не указывать, это значение по умолчанию)

.white-btn {
  background-color: var(--color-name);
}
*/
type ColorButtonPalette = 'primary' | 'warn' | 'accent' | 'other' | undefined;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[lt-button], a[lt-button]',
  templateUrl: './lt-button.component.html',
  styleUrls: ['./lt-button.component.scss'],
  standalone: true,
  imports: [MatRippleModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.disabled]': 'disabled || null',
    // не уверен насчет такого решения по умолчанию, позднее надо будет посмотреть.
    '[tabindex]': '0',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LTButtonComponent implements AfterViewInit {
  private _disabledHover = false;
  private _disabledRipple = false;
  private _disabled = false;

  protected rippleAnimationConfig: RippleAnimationConfig = {
    enterDuration: 350,
    exitDuration: 300,
  };

  @Input()
  get disabledHover(): boolean {
    return this._disabledHover;
  }

  set disabledHover(value: BooleanInput) {
    this._disabledHover = coerceBooleanProperty(value);
  }

  @Input()
  get disabledRipple(): boolean {
    return this._disabledRipple;
  }

  set disabledRipple(value: BooleanInput) {
    this._disabledRipple = coerceBooleanProperty(value);
  }

  // если нужно "отключить" кнопку.
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input() bgColor: ColorButtonPalette;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.initButtonState();
  }

  private initButtonState() {
    this.elementRef.nativeElement.classList.add('lt-btn');

    if (this.bgColor) {
      this.elementRef.nativeElement.classList.add('lt-btn--colored', `lt-btn--${this.bgColor}`);
    } else {
      this.elementRef.nativeElement.classList.add('lt-btn--uncolored');
    }

    if (this.disabledHover || this.disabled) {
      this.elementRef.nativeElement.classList.add('lt-btn--hover-off');
    }
  }

  protected _isRippleDisabled() {
    return this._disabledRipple || this._disabled;
  }

  protected getRippleColor() {
    if (this.bgColor !== undefined) {
      return 'rgb(255 255 255 / 10%)';
    } else {
      return 'rgb(0 0 0 / 4%)';
    }
  }
}
