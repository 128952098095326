import { NgModule } from '@angular/core';
import { LoadingBarComponent } from '@modules/navigation/components/loading-bar/loading-bar.component';
import { SlimLoadingBarService } from '@modules/navigation/components/loading-bar/slim-loading-bar.service';

@NgModule({
  declarations: [LoadingBarComponent],
  exports: [LoadingBarComponent],
  providers: [SlimLoadingBarService],
})
export class LoadingBarModule {}
