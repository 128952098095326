<ul class="validation-list">
  <lt-check-password-item [hasError]="validation.hasMinlengthError" [isTyping]="isTyping">
    {{ 'IDENTITY.SIGN_UP.PASSWORD_CHECK.SYMBOLS_COUNT' | translate }}
  </lt-check-password-item>

  <lt-check-password-item [hasError]="validation.hasNonLatinError" [isTyping]="isTyping">
    {{ 'IDENTITY.SIGN_UP.PASSWORD_CHECK.NON_LATIN' | translate }}
  </lt-check-password-item>

  <lt-check-password-item [hasError]="validation.hasRegisterError" [isTyping]="isTyping">
    {{ 'IDENTITY.SIGN_UP.PASSWORD_CHECK.REGISTER' | translate }}
  </lt-check-password-item>

  <lt-check-password-item [hasError]="validation.hasNumberError" [isTyping]="isTyping">
    {{ 'IDENTITY.SIGN_UP.PASSWORD_CHECK.ONE_NUMBER' | translate }}
  </lt-check-password-item>
</ul>
