import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@shared/modules/shared.module';

@Component({
  selector: 'lt-check-password-item',
  templateUrl: 'check-password-item.component.html',
  styleUrls: ['check-password-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  // надо разобрать этот модуль потом, и импортировать только нужное.
  imports: [SharedModule],
})
export class CheckPasswordItemComponent {
  @Input() hasError: boolean;
  @Input() isTyping: boolean;
}
