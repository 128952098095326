<lt-dialog-with-title [title]="userInfo.headerName">
  <form class="identity-form" [formGroup]="emailConfirmationForm">
    <div class="identity-form__heading">
      <p>{{ 'IDENTITY.CONFIRMATION.ENTER_CODE' | translate }}</p>
      <p class="identity-form__text">
        {{ userInfo.email }}

        <a
          class="identity-form__link identity-form__link--blue"
          *ngIf="userInfo.confirmType === TokenKind.EmailConfirmation"
          (click)="changeEmail()"
          >{{ 'IDENTITY.CONFIRMATION.CHANGE' | translate }}</a
        >
      </p>
    </div>

    <div class="identity-form__fields">
      <mat-form-field appearance="outline" class="lt-textfield" [class.mat-form-field-invalid]="showErrorToken" subscriptSizing="fixed">
        <input
          maxlength="3"
          #tokenInput
          cdkFocusInitial
          (blur)="onBlur()"
          matInput
          type="text"
          formControlName="token"
          class="identity-form__input identity-form__input--center-text"
        />

        <mat-error #tokenErrors="ltErrors" ltErrors="token">
          <span *ngIf="emailConfirmationForm.get('token')?.errors">
            {{ getValidationMessage('token', emailConfirmationForm.get('token').errors) | translate }}
          </span>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</lt-dialog-with-title>
